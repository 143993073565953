import Link from 'next/link';
import { AiOutlineWarning } from 'react-icons/ai';

import FeaturedClassifiedsCarousel from '@/modules/core/components/FeaturedClassifiedsCarousel/FeaturedClassifiedsCarousel';
import { LargeSearchInput } from '@/modules/core/components/LargeSearchInput/LargeSearchInput';
import Layout from '@/modules/core/components/Layout';

const Err404 = () => (
  <>
    <div className="mt-16 md:mt-32 flex justify-center items-center flex-col mx-5">
      <div className="flex flex-col items-start space-y-3 sm:flex-row sm:space-y-0 sm:items-center sm:space-x-3">
        <p className="font-mono text-red-500 text-9xl dark:text-red-600 self-center md:self-start">
          404
        </p>
        <div className="space-y-2 text-justify md:text-left">
          <h1 className="flex items-center space-x-2">
            <AiOutlineWarning size={34} className="text-gray-600" />
            <span className="text-xl font-medium text-gray-600 sm:text-2xl">
              Oops! Page non trouvée.
            </span>
          </h1>
          <p className="text-base font-normal text-gray-600 ">
            La page que vous recherchez n&apos;a pas été trouvée.
          </p>
          <p className="text-base font-normal text-gray-600 ">
            Vous pouvez retourner à{' '}
            <Link href="/" className="text-blue-600 hover:underline">
              l&apos;acceuil
            </Link>{' '}
            ou recherchez sur tayara
          </p>
        </div>
      </div>
      <div className="mx-auto min-w-full max-w-2xl my-7 md:mb-20 relative">
        <LargeSearchInput />
      </div>
    </div>
    <FeaturedClassifiedsCarousel />
  </>
);

Err404.getLayout = function getLayout(page: JSX.Element) {
  return (
    <Layout>
      <Layout.Wide>{page}</Layout.Wide>
    </Layout>
  );
};

export default Err404;
